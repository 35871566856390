// Цвета
$color-black: #000000;
$color-onyx: #111111;
$color-dark-jungle: #222222;
$color-mine-shaft: #333333;
$color-tundora: #444444;
$color-davys-grey: #555555;
$color-ironside-gray: #666666;
$color-tapa-gray: #777777;
$color-taupe-gray: #888888;
$color-mountain-mist: #999999;
$color-dark-gray: #aaaaaa;
$color-gray: #bbbbbb;
$color-celeste: #cccccc;
$color-light-gray: #dddddd;
$color-eallery: #eeeeee;
$color-white: #ffffff;
$color-navy-blue: #0079c1;
$color-prussian-blue: #00426a;
$color-error: #e24831;
$color-carrot-orange: #f39318;
$color-catalina-blue: #19365C;

$color-regal-blue: #133A60;
$color-cobalt: #004C97;
$color-navy-blue: #0077C8;
$color-jordy-blue: #7AB9E5;
$color-supernova: #F9B234;
$color-picton-blue: #54A4C5;
$color-solitude: #E6ECF8;
$color-dim-gray: #626262;
$color-pacific-blue: #009FE3;
$color-eclipse: #3F3F3F;
$color-grey: #858585;
$color-ship-grey: #575756;
$color-green: #7ac05e;
$color-star-dust: #9D9D9C;
$color-denim: #0F62A1;
$color-cerulean: #1882B3;
$color-bright-grey: #57585b;
$color-summer-sky: #3BC1F3;

.color-navy-blue {
	color: $color-navy-blue;
}

.color-cerulean {
	color: $color-cerulean;
}

.color-supernova {
	color: $color-supernova;
}
.color-catalina-blue {
	color: $color-catalina-blue;
}